import React, {FC} from 'react';
import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Input,
	VStack,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {confirm} from '../../../../components/Confirm';
import PuddleSelect from '../../../../components/fields/PuddleSelect';
import PuddleTextarea from '../../../../components/fields/PuddleTextarea';
import usePuddleForm from '../../../../hooks/usePuddleForm';
import {Puddleglum} from '../../../../puddleglum';
import PatientBanner from '../PatientBanner';

interface AddOrderFormProps {
	user: Puddleglum.Models.User;
	treatment?: Puddleglum.Models.Treatment;
	order?: Puddleglum.Models.Order;
	bookingRequest?: Puddleglum.Models.BookingRequest;
	onSave: () => void;
	onClose: () => void;
}

const AddOrEditChargeForm: FC<AddOrderFormProps> = ({
	user,
	treatment,
	bookingRequest,
	order,
	onSave,
	onClose,
}) => {
	if (!treatment && !order) {
		throw new Error('Either treatment or order must be provided');
	}

	const form = usePuddleForm<Puddleglum.Models.Order, Puddleglum.Requests.Provider.AddOrder>({
		initialValues: order || {
			user_id: user.id,
			treatment_id: treatment!.id,
			booking_request_id: bookingRequest?.id,
			status: bookingRequest ? 'booked' : 'pending',
			future_time_value: bookingRequest ? 0 : undefined,
			future_time_unit: bookingRequest ? 'days' : 'weeks',
			provider_id: bookingRequest?.provider_id,
		},
		storeFunction: Puddleglum.Controllers.Providers.OrdersController.store,
		updateFunction: Puddleglum.Controllers.Providers.OrdersController.update,
	});

	const {data: providers} = useQuery(['organization-providers'], async () => {
		const response =
			await Puddleglum.Controllers.Providers.OrganizationProviderController.index({
				page: 1,
				per_page: 10000,
				search: '',
			});
		return response.data.data;
	});

	const {refetch: refetchUserOrders} = useQuery(['recentOrders', user.id]);

	const handleSubmit = async () => {
		const isSaved = await form.save();

		if (isSaved) {
			onSave();
			refetchUserOrders();
		}
	};
	const deleteOrder = async (id: number) => {
		confirm({
			title: 'Are you sure you want to delete this order?',
			onOk: async () => {
				await Puddleglum.Controllers.Providers.OrdersController.destroy(id);
				refetchUserOrders();
				onClose();
			},
		});
	};

	return (
		<form>
			<Heading flex={1} size='md' color='gray.500'>
				{user.first_name} {user.last_name}
			</Heading>
			<PatientBanner patient={user} />
			<VStack spacing={4} mt={4}>
				<HStack w='full'>
					<FormControl flex={2}>
						<FormLabel textColor='gray.500'>Treatment Name</FormLabel>
						<Input
							type='text'
							value={order?.treatment?.treatment || treatment?.treatment}
							isReadOnly
						/>
					</FormControl>
					<FormControl flex={1}>
						<FormLabel textColor='gray.500'>Treatment Code</FormLabel>
						<Input
							type='text'
							value={order?.treatment?.treatment_code || treatment?.treatment_code}
							isReadOnly
						/>
					</FormControl>
				</HStack>

				{providers && (
					<PuddleSelect
						label='Charging Provider'
						options={providers.map((provider) => ({
							label: provider.name,
							value: provider.id,
						}))}
						fieldName='provider_id'
						form={form}
						isRequired
					/>
				)}

				{order && order.id > 0 && (
					<PuddleSelect
						label='Status'
						options={[
							{label: 'Pending', value: 'pending'},
							{label: 'Booked', value: 'booked'},
							{label: 'Completed', value: 'completed'},
							{label: 'Canceled', value: 'canceled'},
						]}
						fieldName='status'
						form={form}
						isRequired
					/>
				)}
				<PuddleTextarea label='Notes' fieldName='notes' form={form} />
			</VStack>
			<Flex mt={8} mb={4} justifyContent='flex-end'>
				<Button variant='outline' onClick={onClose}>
					Close
				</Button>
				{!!order?.id && (
					<Button colorScheme='red' ml={2} onClick={() => deleteOrder(order.id)}>
						Delete Order
					</Button>
				)}
				<Button ml={2} colorScheme='blue' onClick={handleSubmit}>
					Save
				</Button>
			</Flex>
		</form>
	);
};

export default AddOrEditChargeForm;
