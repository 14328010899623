import React, {FC, useState} from 'react';
import {
	Button,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Table,
	Tag,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import parseISO from 'date-fns/parseISO';
import {MdAdd, MdCancel, MdEdit} from 'react-icons/md';
import {confirm} from '../../../components/Confirm';
import SidePanel from '../../../components/SidePanel';
import RequestTimeframes from '../../../enums/RequestTimeframes';
import {Puddleglum} from '../../../puddleglum';
import {AppointmentDate, getCurrentTimezone, MysqlDate} from '../../../utils/format';
import EhrCharges from './EhrCharges';
import ExistingCharges from './ExistingCharges';
import AddOrEditBookingRequestForm from './forms/AddOrEditBookingRequestForm';
import PatientBanner from './PatientBanner';
import TreatmentSearch from './TreatmentSearch';

interface Props {
	disabled?: boolean;
}

const TodaysBookingRequests: FC<Props> = ({disabled}) => {
	const cancelReasonInputRef = React.createRef<HTMLInputElement>();
	const [mode, setMode] = useState<'charges' | 'appointment'>('appointment');
	const [recordToEdit, setRecordToEdit] = useState<Puddleglum.Models.BookingRequest>();

	const {data: bookingRequests, refetch} = useQuery(['todays-booking-requests'], async () => {
		const reply = await Puddleglum.Controllers.Providers.ProviderBookingController.index({
			// today in YYYY-MM-DD format, using date-fns
			start_date: `${MysqlDate(new Date())} 00:00:00`,
			// tomorrow at midnight
			end_date: `${MysqlDate(new Date())} 23:59:59`,
			status: 'confirmed,completed',
			timezone: getCurrentTimezone(),
		});
		return reply.data;
	});

	const cancelBookingRequest = async (bookingRequest: Puddleglum.Models.BookingRequest) => {
		confirm({
			title: 'Cancel this booking request?',
			content: (
				<FormControl>
					<FormLabel>Enter the cancellation reason.</FormLabel>
					<Input ref={cancelReasonInputRef} isRequired placeholder='Reason' max='255' />
				</FormControl>
			),
			validate: () => {
				if (!cancelReasonInputRef.current?.value) {
					return 'Please enter a reason for the cancellation.';
				}
				return true;
			},
			onOk: async () => {
				await Puddleglum.Controllers.Providers.ProviderBookingController.update(
					bookingRequest.id,
					{
						location_id: Number(bookingRequest.location_id),
						starts_at: String(bookingRequest.starts_at),
						status: 'canceled',
						provider_id: Number(bookingRequest.provider_id),
						cancel_reason: cancelReasonInputRef.current?.value,
					},
				);
				refetch();
			},
		});
	};

	const handleClose = () => {
		setRecordToEdit(undefined);
		refetch();
	};

	if (!bookingRequests) return null;

	return (
		<>
			<Table>
				<Thead>
					<Tr>
						<Th>Patient</Th>
						<Th>Timeframe</Th>
						<Th>Provider / Location</Th>
						<Th>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{bookingRequests.map((bookingRequest) => (
						<Tr key={bookingRequest.id}>
							<Td>
								{bookingRequest.user!.first_name} {bookingRequest.user!.last_name}
							</Td>
							<Td>
								{bookingRequest.starts_at
									? AppointmentDate(parseISO(bookingRequest.starts_at))
									: RequestTimeframes[
											bookingRequest.requested_timeframe as keyof typeof RequestTimeframes
										]}
							</Td>
							<Td>
								{bookingRequest.provider ? bookingRequest.provider.name : 'N/A'}

								{!!bookingRequest.location && (
									<>
										<br />
										<Tag>
											{bookingRequest.location
												? `${bookingRequest.location.name}`
												: ''}
										</Tag>
									</>
								)}
							</Td>
							<Td>
								{bookingRequest.status !== 'canceled' ? (
									<>
										<Button
											size='xs'
											colorScheme='teal'
											leftIcon={<MdAdd />}
											mr={1}
											onClick={() => {
												setMode('charges');
												setRecordToEdit(bookingRequest);
											}}
											disabled={disabled}
										>
											Charges
										</Button>
										<Button
											size='xs'
											colorScheme='blue'
											leftIcon={<MdEdit />}
											mr={1}
											onClick={() => {
												setMode('appointment');
												setRecordToEdit(bookingRequest);
											}}
											disabled={disabled}
										>
											Edit
										</Button>
										<Button
											size='xs'
											colorScheme='red'
											leftIcon={<MdCancel />}
											onClick={() => cancelBookingRequest(bookingRequest)}
											disabled={disabled}
										>
											Cancel
										</Button>
									</>
								) : (
									'N/A'
								)}
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			<SidePanel title='Edit Booking Request' isOpen={!!recordToEdit} onClose={handleClose}>
				{recordToEdit && mode === 'appointment' && (
					<AddOrEditBookingRequestForm
						bookingRequest={recordToEdit}
						onSave={handleClose}
						onClose={handleClose}
					/>
				)}
			</SidePanel>
			<SidePanel
				title='Add Charges'
				isOpen={!!recordToEdit && mode === 'charges'}
				onClose={handleClose}
			>
				{recordToEdit?.user && (
					<Heading flex={1} size='md' color='gray.500'>
						{recordToEdit.user.first_name} {recordToEdit.user.last_name}
					</Heading>
				)}
				{recordToEdit?.user && <PatientBanner patient={recordToEdit.user} />}
				{recordToEdit?.user && (
					<TreatmentSearch user={recordToEdit.user} bookingRequest={recordToEdit} />
				)}
				{recordToEdit?.user && (
					<ExistingCharges user={recordToEdit.user} bookingRequest={recordToEdit} />
				)}
				{recordToEdit?.user && (
					<EhrCharges user={recordToEdit.user} bookingRequest={recordToEdit} />
				)}
			</SidePanel>
		</>
	);
};

export default TodaysBookingRequests;
