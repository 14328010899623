import React, {FC, useEffect, useState} from 'react';
import {Box, Heading, HStack, Icon, Select, Spacer} from '@chakra-ui/react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {MdInfo} from 'react-icons/md';
import BodyContainer from '../../../components/BodyContainer';
import DatePicker from '../../../components/DatePicker';
import {useNotifications} from '../../../components/NotificationContext';
import PaymentAnalyticCard from '../../../components/PaymentAnalyticCard';
import TopMenu from '../../../components/TopMenu';
import TouchableHover from '../../../components/TouchableHover';
import useMe from '../../../hooks/useMe';
import usePermission from '../../../hooks/usePermission';
import {Puddleglum} from '../../../puddleglum';
import {AppointmentStatus} from '../../../types/AppointmentStatus';
import LineGraph from '../../admin/components/LineGraph';
import BookingRequestsByDateAndStatus from '../components/BookingRequestsByDateAndStatus';
import ProviderOnboarding from '../components/ProviderOnboarding';
import TodaysBookingRequests from '../components/TodaysBookingRequests';

const ProviderPortalHomeScreen: FC = () => {
	const queryClient = useQueryClient();
	const [date, setDate] = useState(new Date());
	const [isReadOnly, setIsReadOnly] = useState<boolean>();
	const [status, setStatus] = useState<AppointmentStatus>('pending');
	const {me} = useMe();
	const {registerCallback} = useNotifications();
	const permission = usePermission('providers');

	const {data: paymentsByMonth, refetch} = useQuery(['providerHomePaymentsByMonth'], async () => {
		const reply =
			await Puddleglum.Controllers.Providers.ProviderAnalyticsController.getPaymentsByMonth();
		return reply.data;
	});

	useEffect(() => {
		if (permission === 'all') {
			setIsReadOnly(false);
		}
		if (permission === 'read') {
			setIsReadOnly(true);
		}
		refetch();
	}, [permission]);

	useEffect(() => {
		if (me?.organization_id) {
			registerCallback(`organization.${me.organization_id}`, () => {
				queryClient.invalidateQueries(['bookingRequests']);
			});
		}
	}, [me?.organization_id]);

	return (
		<>
			<TopMenu>Millennia Platform: Provider Portal</TopMenu>
			<HStack w='auto' alignItems='stretch' mt={4} mr={4} spacing={4}>
				<Box flex={1}>
					<BodyContainer border='solid 1px' flex={2} p={4} mb={4}>
						<HStack w='full' justifyContent='space-between'>
							<Heading size='md' color='gray.700'>
								Appointment Requests
							</Heading>
							<Spacer />
							<Box>
								<DatePicker value={date} onChange={setDate} />
							</Box>
							<Box>
								<Select
									size='sm'
									onChange={(event) => {
										setStatus(event.target.value as AppointmentStatus);
									}}
								>
									<option value='pending'>Pending</option>
									<option value='confirmed'>Confirmed</option>
									<option value='completed'>Completed</option>
									<option value='canceled'>Canceled</option>
									<option value='pending,confirmed,completed,canceled'>
										All
									</option>
								</Select>
							</Box>
						</HStack>
						<BookingRequestsByDateAndStatus
							status={status}
							date={date}
							disabled={isReadOnly}
						/>
					</BodyContainer>
					<BodyContainer border='solid 1px' flex={2} p={4} mb={4}>
						<HStack w='full' justifyContent='space-between'>
							<Heading size='md' color='gray.700'>
								Today&apos;s Appointments
							</Heading>
						</HStack>
						<TodaysBookingRequests disabled={isReadOnly} />
					</BodyContainer>

					<BodyContainer border='solid 1px' flex={2} p={4} mb={4}>
						<Heading size='md' mb={4} color='gray.700'>
							Payments by Month
						</Heading>
						<LineGraph
							data={paymentsByMonth as any[]}
							xAxisKey='date'
							lines={[
								{
									dataKey: 'total',
									label: 'Total Payments in USD',
									color: '#319795',
								},
							]}
							w='auto'
							h='400px'
							p={4}
							border='1px solid'
							borderColor='gray.100'
							borderRadius='md'
						/>
					</BodyContainer>
				</Box>
				<Box w='400px'>
					<BodyContainer border='solid 1px' flex={2} p={4}>
						<HStack w='full' justifyContent='space-between'>
							<Heading size='md' color='gray.700'>
								Onboarding
							</Heading>
						</HStack>
						<ProviderOnboarding />
					</BodyContainer>
					<BodyContainer border='solid 1px' flex={2} p={4} mt={4}>
						<Heading size='md' color='gray.700'>
							Pending Payments
							<Box float='right'>
								<TouchableHover label='This amount represent unpaid billing statements. This amount should be paid to you soon!'>
									<span>
										<Icon as={MdInfo} color='gray.500' />
									</span>
								</TouchableHover>
							</Box>
						</Heading>
						<PaymentAnalyticCard type='pending' scope='organization' />
					</BodyContainer>
					<BodyContainer border='solid 1px' flex={2} p={4} mt={4}>
						<Heading size='md' color='gray.700'>
							Recent Payments
							<Box float='right'>
								<TouchableHover label='This is the amount you have been paid by Millennia in the past 30 days.'>
									<span>
										<Icon as={MdInfo} color='gray.500' />
									</span>
								</TouchableHover>
							</Box>
						</Heading>
						<PaymentAnalyticCard type='recent' scope='organization' />
					</BodyContainer>
					<BodyContainer border='solid 1px' flex={2} p={4} mt={4}>
						<Heading size='md' color='gray.700'>
							All Payments
							<Box float='right'>
								<TouchableHover label='This is the amount you have been by Millennia for all time.'>
									<span>
										<Icon as={MdInfo} color='gray.500' />
									</span>
								</TouchableHover>
							</Box>
						</Heading>
						<PaymentAnalyticCard type='all' scope='organization' />
					</BodyContainer>
				</Box>
			</HStack>
		</>
	);
};

export default ProviderPortalHomeScreen;
