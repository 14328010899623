import React, {FC, useState} from 'react';
import {
	Button,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Table,
	Tag,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import parseISO from 'date-fns/parseISO';
import {MdAdd, MdCancel, MdCheck} from 'react-icons/md';
import {confirm} from '../../../components/Confirm';
import SidePanel from '../../../components/SidePanel';
import RequestTimeframes from '../../../enums/RequestTimeframes';
import {Puddleglum} from '../../../puddleglum';
import {AppointmentStatus} from '../../../types/AppointmentStatus';
import {AppointmentDate, getCurrentTimezone, MysqlDate} from '../../../utils/format';
import ExistingCharges from './ExistingCharges';
import AddOrEditBookingRequestForm from './forms/AddOrEditBookingRequestForm';
import PatientBanner from './PatientBanner';
import TreatmentSearch from './TreatmentSearch';

interface BookingRequestsTableProps {
	date?: Date;
	status?: AppointmentStatus;
	disabled?: boolean;
}

const BookingRequestsByDateAndStatus: FC<BookingRequestsTableProps> = ({
	date = new Date(),
	status = 'pending',
	disabled,
}) => {
	const queryClient = useQueryClient();
	const cancelReasonInputRef = React.createRef<HTMLInputElement>();
	const [recordToEdit, setRecordToEdit] = useState<Puddleglum.Models.BookingRequest>();
	const [mode, setMode] = useState<'charges' | 'appointment'>('appointment');
	const {data: bookingRequests, refetch} = useQuery(
		['bookingRequests', status, date],
		async () => {
			const reply = await Puddleglum.Controllers.Providers.ProviderBookingController.index({
				status,
				start_date: `${MysqlDate(date)} 00:00:00`,
				end_date: `${MysqlDate(date)} 23:59:59`,
				timezone: getCurrentTimezone(),
			});

			return reply.data;
		},
		{
			refetchInterval: 1000 * 60,
			refetchIntervalInBackground: true,
			refetchOnWindowFocus: 'always',
		},
	);

	const cancelBookingRequest = async (bookingRequest: Puddleglum.Models.BookingRequest) => {
		confirm({
			title: 'Cancel this booking request?',
			content: (
				<FormControl>
					<FormLabel>Enter the cancellation reason.</FormLabel>
					<Input ref={cancelReasonInputRef} isRequired placeholder='Reason' max='255' />
				</FormControl>
			),
			validate: () => {
				if (!cancelReasonInputRef.current?.value) {
					return 'Please enter a reason for the cancellation.';
				}
				return true;
			},
			onOk: async () => {
				await Puddleglum.Controllers.Providers.ProviderBookingController.update(
					bookingRequest.id,
					{
						location_id: Number(bookingRequest.location_id),
						status: 'canceled',
						cancel_reason: cancelReasonInputRef.current?.value,
					},
				);
				refetch();
				queryClient.invalidateQueries(['todays-booking-requests']);
			},
		});
	};

	const handleClose = () => {
		setRecordToEdit(undefined);
		refetch();
		queryClient.invalidateQueries(['todays-booking-requests']);
	};

	const handleSave = () => {
		setRecordToEdit(undefined);
		refetch();
		queryClient.invalidateQueries(['todays-booking-requests']);
	};

	if (!bookingRequests) return null;

	return (
		<>
			<Table>
				<Thead>
					<Tr>
						<Th>Patient</Th>
						<Th>Timeframe</Th>
						<Th>Reason</Th>
						<Th>Provider / Location</Th>
						<Th>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{bookingRequests.map((bookingRequest) => (
						<Tr key={bookingRequest.id}>
							<Td>
								{bookingRequest.user!.first_name} {bookingRequest.user!.last_name}
							</Td>
							<Td>
								{bookingRequest.starts_at
									? AppointmentDate(parseISO(bookingRequest.starts_at))
									: RequestTimeframes[
											bookingRequest.requested_timeframe as keyof typeof RequestTimeframes
										]}
							</Td>
							<Td>{bookingRequest.reason}</Td>
							<Td>
								{bookingRequest.provider ? bookingRequest.provider.name : 'N/A'}

								{!!bookingRequest.location && (
									<>
										<br />
										<Tag>
											{bookingRequest.location
												? `${bookingRequest.location.name}`
												: ''}
										</Tag>
									</>
								)}
							</Td>
							<Td>
								{['confirmed', 'completed'].includes(bookingRequest.status) && (
									<Button
										size='xs'
										colorScheme='teal'
										leftIcon={<MdAdd />}
										mr={1}
										onClick={() => {
											setMode('charges');
											setRecordToEdit(bookingRequest);
										}}
										disabled={disabled}
									>
										Charges
									</Button>
								)}
								{bookingRequest.status !== 'canceled' ? (
									<>
										<Button
											size='xs'
											colorScheme='blue'
											leftIcon={<MdCheck />}
											mr={1}
											onClick={() => {
												setMode('appointment');
												setRecordToEdit(bookingRequest);
											}}
											disabled={disabled}
										>
											{bookingRequest.status === 'pending'
												? 'Confirm'
												: 'Edit'}
										</Button>
										<Button
											size='xs'
											colorScheme='red'
											leftIcon={<MdCancel />}
											onClick={() => cancelBookingRequest(bookingRequest)}
											disabled={disabled}
										>
											Cancel
										</Button>
									</>
								) : (
									'N/A'
								)}
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			<SidePanel
				title='Confirm Booking Request'
				isOpen={!!recordToEdit && mode === 'appointment'}
				onClose={handleClose}
			>
				{recordToEdit && (
					<AddOrEditBookingRequestForm
						bookingRequest={recordToEdit}
						fieldsToUpdate={{status: 'confirmed'}}
						onSave={handleSave}
						onClose={handleClose}
					/>
				)}
			</SidePanel>
			<SidePanel
				title='Add Charges'
				isOpen={!!recordToEdit && mode === 'charges'}
				onClose={handleClose}
			>
				{recordToEdit?.user && (
					<Heading flex={1} size='md' color='gray.500'>
						{recordToEdit.user.first_name} {recordToEdit.user.last_name}
					</Heading>
				)}
				{recordToEdit?.user && <PatientBanner patient={recordToEdit.user} />}
				{recordToEdit?.user && (
					<TreatmentSearch user={recordToEdit.user} bookingRequest={recordToEdit} />
				)}
				{recordToEdit?.user && (
					<ExistingCharges user={recordToEdit.user} bookingRequest={recordToEdit} />
				)}
			</SidePanel>
		</>
	);
};

export default BookingRequestsByDateAndStatus;
